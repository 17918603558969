import HttpRequestor, { BaseResponse } from './httpRequestor';

export class VerifyService {
  constructor(private httpRequestor: HttpRequestor) {}

  public async confirmToken(
    token: string,
  ): Promise<BaseResponse<{ data: []; error: string }>> {
    return this.httpRequestor.post(`/recaptcha/verify`, {
      token,
    });
  }
}
