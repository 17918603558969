import getConfig from 'next/config';
import React, { createContext, useCallback, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { BaseResponse, fetcher } from 'services/httpRequestor';
import { VerifyService } from 'services/verifyService';

const { publicRuntimeConfig } = getConfig();

export const RecaptchaContext = createContext<{
  getRecaptchaToken: () => Promise<string | null>;
  executeVerify: (
    recaptchaToken: string,
  ) => Promise<BaseResponse<{ data: []; error: string }>>;
}>({
  getRecaptchaToken: () => Promise.reject(),
  executeVerify: () => Promise.reject(),
});

const RecaptchaContextProvider = ({
  children,
}: React.PropsWithChildren<any>) => {
  const recaptchaRef: React.RefObject<ReCAPTCHA> = React.createRef();

  const getRecaptchaToken = useCallback(() => {
    if (!recaptchaRef.current) {
      return Promise.resolve(null);
    }
    return recaptchaRef.current.executeAsync();
  }, [recaptchaRef]);

  const executeVerify = useCallback(async (recaptchaToken: string) => {
    const verifyService = new VerifyService(fetcher());
    return verifyService.confirmToken(recaptchaToken);
  }, []);

  return (
    <RecaptchaContext.Provider
      value={{
        getRecaptchaToken,
        executeVerify,
      }}
    >
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={publicRuntimeConfig.recaptchaSiteKey}
      />
    </RecaptchaContext.Provider>
  );
};

export default RecaptchaContextProvider;

export const useRecaptchaContext = () => useContext(RecaptchaContext);
